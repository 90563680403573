<template>
  <div class="row">
    <div class="col-9">
      <h5 class="pl-1 font-weight-bold my-2">Rapid/PCR Test</h5>
    </div>
    <div class="col-12">
      <medical-nav></medical-nav>
    </div>
    <div class="col-12">
      <table class="table table-sm table-bordered small">
        <thead>
        <tr>
          <td class="align-middle text-center font-weight-bold">Test Type</td>
          <td class="align-middle text-center font-weight-bold">Place Conducted</td>
          <td class="align-middle text-center font-weight-bold">Country/Airport/Port/Vessel</td>
          <td class="align-middle text-center font-weight-bold">Lab/Hospital</td>
          <td class="align-middle text-center font-weight-bold">Other Hospital/Lab</td>
          <td class="align-middle text-center font-weight-bold">Date Conducted</td>
          <td class="align-middle text-center font-weight-bold">Date Result</td>
          <td class="align-middle text-center font-weight-bold">Result</td>
          <td class="align-middle text-center font-weight-bold">Days to Expire</td>
          <td class="align-middle text-center font-weight-bold">Action</td>
        </tr>
        </thead>
        <tbody>
          <template v-if="crewDocuments">
            <tr v-for="pcr in crewDocuments">
              <td>{{pcr.crew_covid_test_type ? pcr.crew_covid_test_type.name : ''}}</td>
              <td>{{pcr.crew_covid_test_conducted_place ? pcr.crew_covid_test_conducted_place.name : ''}}</td>
              <td>{{pcr.conducted_place_sub}}</td>
              <td> {{
                  pcr.crew_covid_test_country_hospital
                    ? pcr.crew_covid_test_country_hospital.name
                    : "N/A"
                }}</td>
              <td>
                {{
                  pcr.others
                    ? pcr.others
                    : "N/A"
                }}
              </td>
              <td>{{ pcr.formatted_conducted_date }}</td>
              <td>{{ pcr.formatted_result_date }}</td>
              <td  class="align-middle"
                  :class="{
                  'text-danger font-weight-bold':
                    pcr.crew_covid_test_type_result.is_positive_result,
                }">
                {{ pcr.crew_covid_test_type_result.name }}
              </td>
              <td
                class="align-middle"
                :class="{
                  'text-danger font-weight-bold':
                    pcr.days_to_expire <= 15 || 'Expired',
                }"
              >
                {{ pcr.days_to_expire }}
              </td>
              <td class="text-center">
                <button
                  data-toggle="modal"
                  data-target="#rtPcr"
                  @click="viewRtPcrFile(pcr)"
                  class="btn btn-xs btn-secondary"
                >
                  View
                </button>
              </td>
            </tr>
          </template>

        </tbody>
      </table>

      <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="rtPcr" tabindex="-1" aria-labelledby="crewMedical" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style="height:auto">
            <div class="modal-header">
              <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW RTPCR TEST</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-5">
                  <table class="w-100">
                    <tr>
                      <td>Test Type:</td>
                      <td class="font-weight-bold">{{pcr.crew_covid_test_type ? pcr.crew_covid_test_type.name : '' }}</td>
                    </tr>
                    <tr>
                      <td>Place Conducted:</td>
                      <td class="font-weight-bold">
                        {{pcr.crew_covid_test_conducted_place ? pcr.crew_covid_test_conducted_place.name : ''}}
                      </td>
                    </tr>
                    <tr>
                      <td>Country/Airport/Port/Vessel:</td>
                      <td class="font-weight-bold">{{pcr.conducted_place_sub ? pcr.conducted_place_sub : ''}}</td>
                    </tr>
                    <tr>
                      <td>Hospital/Lab:</td>
                      <td class="font-weight-bold">{{pcr.crew_covid_test_country_hospital ? pcr.crew_covid_test_country_hospital.name : ''}}</td>
                    </tr>
                    <tr>
                      <td>Other Hospital/Lab:</td>
                      <td class="font-weight-bold">{{pcr.others}}</td>
                    </tr>
                    <tr>
                      <td>Date Conducted:</td>
                      <td class="font-weight-bold">{{pcr.formatted_conducted_date}}</td>
                    </tr>
                    <tr>
                      <td>Date Result:</td>
                      <td class="font-weight-bold">{{pcr.formatted_result_date}}</td>
                    </tr>
                    <tr>
                      <td>Result:</td>
                      <td class="font-weight-bold">{{pcr.crew_covid_test_type_result ? pcr.crew_covid_test_type_result.name : ''}}</td>
                    </tr>
                  </table>
                  <hr>
                </div>
                <div class="col-7" style="height: 77vh">
                  <template v-if="pcrFile">
                    <iframe style="height: inherit; width: 100%" :src="pcrFile"></iframe>
                  </template>
                  <div v-else class="alert alert-danger">
                    NO FILE FOUND
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalNav from "@/components/crew/document/medical/MedicalNav";
import {mapActions, mapGetters} from "vuex";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "Pcr",
  components:{MedicalNav},
  data(){
    return {
      pcr:{},
      pcrFile:''
    }
  },
  computed:{
    ...mapGetters(['crewDocuments']),
  },
  methods:{
    ...mapActions(['getCrewRtPcr']),
    async viewRtPcrFile(pcr){
      this.pcrFile=false;
      this.pcr=Object.assign({},pcr);
      const crewDocumentFile= await CrewService.getRtPcrTestFileFromCms({rtpcrFile: pcr.id});
      this.pcrFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
    }
  },
  async created() {
    const params= {
      cms_id:this.$route.params.id
    }
   await this.getCrewRtPcr(params);
  }
}
</script>

<style scoped>

</style>
